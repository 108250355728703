import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Paper,
  Chip,
  Divider,
  Grid,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import ImageIcon from '@mui/icons-material/Image';
import { BASE_URL } from '../utils/IP';

const TappComplaintDetails = () => {
  const { complaintId } = useParams();
  const navigate = useNavigate();
  const { complaints, loading } = useSelector(state => state.dueAndComplaint);
  const [comment, setComment] = useState('');
  const [commentMedia, setCommentMedia] = useState(null);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const complaint = complaints.find(c => c._id === complaintId);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleImageClick = (imageUrl) => {
    setPreviewImage(imageUrl);
    setOpenImagePreview(true);
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('text', comment);
    formData.append('commenterType', 'tenant');
    if (commentMedia) {
      formData.append('media', commentMedia);
    }

    try {
      await axios.post(`${BASE_URL}/complaints/${complaintId}/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token')
        }
      });
      setComment('');
      setCommentMedia(null);
      // The WebSocket middleware will handle updating the Redux store
    } catch (error) {
      console.error('Error submitting comment:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  if (loading || !complaint) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', p: 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Complaint Details</Typography>
      </Box>

      <Paper elevation={0} sx={{ m: 2, p: 2 }}>
        <Typography variant="h6" gutterBottom>{complaint.category}</Typography>
        <Chip 
          label={complaint.status} 
          color={complaint.status === 'Resolved' ? 'success' : 'warning'} 
          sx={{ mb: 2 }}
        />
        <Typography variant="body2" color="textSecondary" paragraph>
          Reported on: {new Date(complaint.date).toLocaleDateString()}
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
          Complaint Description:
        </Typography>
        <Typography variant="body1" paragraph>
          {complaint.description}
        </Typography>

        {complaint.media && complaint.media.length > 0 && (
          <>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
              Attached Media:
            </Typography>
            <Grid container spacing={2}>
              {complaint.media.map((mediaPath, index) => (
                <Grid item xs={4} key={index}>
                  <Box 
                    component="img"
                    src={`${BASE_URL}/${mediaPath}`}
                    alt={`Complaint media ${index + 1}`}
                    sx={{ 
                      width: '100%', 
                      height: '100px', 
                      objectFit: 'cover', 
                      borderRadius: '8px',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleImageClick(`${BASE_URL}/${mediaPath}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Comments:
        </Typography>
        <List>
          {complaint.comments && complaint.comments.map((comment, index) => (
            <ListItem 
              key={index} 
              alignItems="flex-start"
              sx={{
                flexDirection: comment.commenterType === 'homeowner' ? 'row' : 'row-reverse',
                justifyContent: comment.commenterType === 'homeowner' ? 'flex-start' : 'flex-end',
              }}
            >
              <ListItemAvatar>
                <Avatar><PersonIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={comment.text}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.primary"
                      sx={{ display: 'block', fontSize: '0.8rem', mt: 0.5 }}
                    >
                      {new Date(comment.date).toLocaleString()}
                    </Typography>
                    {comment.media && (
                      <Box 
                        component="img"
                        src={`${BASE_URL}/${comment.media}`}
                        alt={`Comment media ${index + 1}`}
                        sx={{ 
                          width: '100px', 
                          height: '100px', 
                          objectFit: 'cover', 
                          borderRadius: '8px',
                          mt: 1,
                          cursor: 'pointer'
                        }}
                        onClick={() => handleImageClick(`${BASE_URL}/${comment.media}`)}
                      />
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>

        <Box component="form" onSubmit={handleCommentSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Add a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="comment-media-file"
                    type="file"
                    onChange={(e) => setCommentMedia(e.target.files[0])}
                  />
                  <label htmlFor="comment-media-file">
                    <IconButton component="span">
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                </>
              ),
            }}
          />
          {commentMedia && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <ImageIcon sx={{ mr: 1 }} />
              <Typography variant="body2">{commentMedia.name}</Typography>
            </Box>
          )}
        </Box>

        {complaint.resolution && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
              Resolution:
            </Typography>
            <Typography variant="body1" paragraph>
              {complaint.resolution.text}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              Resolved on: {new Date(complaint.resolution.date).toLocaleString()}
            </Typography>
            {complaint.resolution.media && (
              <Box 
                component="img"
                src={`${BASE_URL}/${complaint.resolution.media}`}
                alt="Resolution media"
                sx={{ 
                  width: '100px', 
                  height: '100px', 
                  objectFit: 'cover', 
                  borderRadius: '8px',
                  mt: 1,
                  cursor: 'pointer'
                }}
                onClick={() => handleImageClick(`${BASE_URL}/${complaint.resolution.media}`)}
              />
            )}
          </>
        )}
      </Paper>

      <Dialog open={openImagePreview} onClose={() => setOpenImagePreview(false)} maxWidth="md">
        <img src={previewImage} alt="Full size preview" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </Box>
  );
};

export default TappComplaintDetails;