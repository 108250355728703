import React from 'react';
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  IconButton
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getPaidDues } from '../features/duesComplaintsSlice'; // Redux actions



const AllTranscations = () => {

  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.dueAndComplaint.loading);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const alldues  = useSelector (state => state.dueAndComplaint.dues);
  console.log('in transactionss',alldues)

  const transactions= alldues.filter((due) => due.status === 'Paid')
  .sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime));

  console.log('in transactionss 2222',transactions)

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous screen
  };

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

return (

    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', p: 0 }}>

        <Box sx={{ display: 'flex', alignItems: 'center', p:2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)'}}>
            <IconButton edge="start" color="inherit" onClick={handleBackClick}>
            <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">All Transactions</Typography>
        </Box>
        
      <Grid container spacing={2} sx={{ mt: 1, paddingRight: 3, paddingLeft: 3 }}>
          {transactions.map((transaction, index) => (
            <Grid item xs={12} key={transaction._id}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ReportProblemIcon sx={{ mr: 2, color: '#000'}} />
                  <Box>
                    <Typography variant="body1">{transaction.dueType}</Typography>
                    <Typography variant="body2" color="textSecondary">{new Date(transaction.paymentDateTime).toLocaleDateString()}, {transaction.status}</Typography>
                    
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>₹{transaction.paymentAmount}</Typography>
              </Box>
              <Box sx={{ height: '1px', bgcolor: '#D9D9D9',mt:2 ,opacity:1}} />
            </Grid>
          ))}
        </Grid>
    </Box>
  );
};

export default AllTranscations;