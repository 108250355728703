import React, { useState } from 'react';
import { Box, Typography, Avatar, IconButton, Card, CardContent, Button, Divider, Grid, Link, Drawer, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../features/authSlice';

const BottomCustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#FFF',
    borderRadius: '20px 20px 0 0',
  },
}));

const YourAccount = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tenantDetails = useSelector((state) => state.dueAndComplaint.currentOccupancy);
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.dueAndComplaint.loading);

  if (loading || !user || !tenantDetails) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

 
  const handleLogout = async () => {
    dispatch(logout());
    navigate('/tapp-login');
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleBackClick = () => {
    navigate('/tapp-profile');
  };

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', pt: 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Your Account</Typography>
      </Box>

      <Card sx={{ m: 2, bgcolor: '#FFF', borderRadius: 2, boxShadow: 0 }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <Avatar
            src={`${process.env.PUBLIC_URL}/icons/DP.png`}
            alt="Profile"
            sx={{ width: 80, height: 80, mb: 1 }}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{tenantDetails?.tenant.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {tenantDetails?.room?.name}, {tenantDetails?.room?.floor}, {tenantDetails?.property?.name}, {tenantDetails?.property?.address}, {tenantDetails?.property?.city} - {tenantDetails?.property?.pincode}
          </Typography>
        </CardContent>
      </Card>

      <Divider sx={{ my: 2, mt: -1, ml: 2, mr: 2 }} />

      <Box sx={{ p: 2, ml: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Profile Information</Typography>
        <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Email</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">manish@gmail.com</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Primary number</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{tenantDetails?.tenant.phone}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Alternate number</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">9876547699</Typography>
          </Grid>
        </Grid>
        <Link href="#" underline="hover" sx={{ display: 'flex', alignItems: 'center', mt: 2, color: '#03A0A2', textDecoration: 'underline' }}>
          <EditIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
          <Typography variant="body2">Edit details</Typography>
        </Link>
      </Box>

      <Divider sx={{ my: 2, ml: 2, mr: 2 }} />

      <Box sx={{ p: 2, ml: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Room Details</Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Room number</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{tenantDetails?.room?.name}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Floor</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{tenantDetails?.room?.floor}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Monthly rent</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">₹{tenantDetails?.fixedRent}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Security deposit</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">₹{tenantDetails?.securityDeposit}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="body2" color="textSecondary">Joined on</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1">{new Date(tenantDetails?.moveIn).toLocaleDateString()}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 2, mt: 2 }}>
      <Button
          variant="outlined"
          color="error"
          fullWidth
          sx={{ borderColor: '#f44336', color: '#f44336' }}
          onClick={handleDrawerOpen}
        >
          Logout
        </Button>
      </Box>

      {/* Logout Confirmation Drawer */}
      <BottomCustomDrawer anchor="bottom" open={drawerOpen} onClose={handleDrawerClose}>
        <Box sx={{ p: 2, width: 'auto' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Logout?</Typography>
          <Typography>Are you sure you want to log out? You can log back in anytime you want.</Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ bgcolor: '#03A0A2', color: '#fff', mt: 2, mb: 2, p: 1.5, fontWeight: '600', borderRadius: '10px' }}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </BottomCustomDrawer>
    </Box>
  );
};

export default YourAccount;