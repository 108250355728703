// src/services/websocketService.js
import { io } from 'socket.io-client';
import { BASE_URL } from '../utils/IP';

class WebSocketService {
  constructor() {
    this.socket = null;
    this.listeners = new Map();
    this.reconnectTimer = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.isConnecting = false;
    this.connectionTimeout = null;
  }

  connect(token) {
    if (this.isConnecting) {
      console.log('Connection attempt already in progress');
      return;
    }

    if (this.socket && this.socket.connected) {
      console.log('Already connected to WebSocket');
      return;
    }

    this.isConnecting = true;

    if (this.socket) {
      this.socket.close();
    }

    this.socket = io('https://tenant.rizzy.in', {
      auth: { token },
      transports: ['websocket'],
      reconnection: false, // We'll handle reconnection manually
      timeout: 10000, // 10 seconds connection timeout
    });

    this.setupEventHandlers();
    this.setupListeners();

    // Set a connection timeout
    this.connectionTimeout = setTimeout(() => {
      if (!this.socket.connected) {
        console.log('WebSocket connection timeout');
        this.socket.close();
        this.isConnecting = false;
        this.reconnectWithBackoff();
      }
    }, 10000);
  }

  setupEventHandlers() {
    this.socket.on('connect', () => {
      console.log('Connected to WebSocket');
      clearTimeout(this.connectionTimeout);
      this.isConnecting = false;
      this.reconnectAttempts = 0;
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected from WebSocket:', reason);
      this.isConnecting = false;
      this.reconnectWithBackoff();
    });

    this.socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      clearTimeout(this.connectionTimeout);
      this.isConnecting = false;
      this.reconnectWithBackoff();
    });
  }

  reconnectWithBackoff() {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.log('Max reconnection attempts reached. Please check your connection and try again later.');
      return;
    }

    const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);
    this.reconnectTimer = setTimeout(() => {
      this.reconnectAttempts++;
      console.log(`Attempting to reconnect... (Attempt ${this.reconnectAttempts})`);
      this.connect(localStorage.getItem('token'));
    }, delay);
  }

  reconnectIfNeeded() {
    const token = localStorage.getItem('token');
    if (token && (!this.socket || !this.socket.connected) && !this.isConnecting) {
      console.log('Reconnecting WebSocket');
      this.connect(token);
    } else {
      console.log('WebSocket reconnection not needed');
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }
    if (this.connectionTimeout) {
      clearTimeout(this.connectionTimeout);
    }
    this.reconnectAttempts = 0;
    this.isConnecting = false;
  }

  setupListeners() {
    ['PROPERTY_UPDATE', 'TENANT_UPDATE', 'ROOM_UPDATE', 'DUE_UPDATE', 'COMPLAINT_UPDATE'].forEach(event => {
      this.socket.on(event, (data) => this.notifyListeners(event, data));
    });
  }

  on(event, callback) {
    if (!this.listeners.has(event)) {
      this.listeners.set(event, new Set());
    }
    this.listeners.get(event).add(callback);
  }

  off(event, callback) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).delete(callback);
    }
  }

  notifyListeners(event, data) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).forEach(callback => callback(data));
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }
}

export default new WebSocketService();