import React from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Grid,
  ButtonBase,
  CircularProgress, IconButton
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const TenantDueList = () => {
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.dueAndComplaint.loading);
  const dues  = useSelector (state => state.dueAndComplaint.dues);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous screen
  };

  if (loading || !user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }


  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', p: 0 }}>

        <Box sx={{ display: 'flex', alignItems: 'center', p:2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)'}}>
            <IconButton edge="start" color="inherit" onClick={handleBackClick}>
            <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">Your all Dues</Typography>
        </Box>
        
      <Grid container spacing={2} sx={{ mt: 1, paddingRight: 3, paddingLeft: 3 }}>
        {dues.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ textAlign: 'center' }}>No Dues yet</Typography>
          </Grid>
        ) : (
            dues.map((due, index) => (
            <Grid item xs={12} key={due._id}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ReportProblemIcon sx={{ mr: 2, color: '#000' }} />
                  <Box>
                    <Typography variant="body1">{due.dueType}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(due.dueDate).toLocaleDateString()}, Status: {due.status}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {due.totalDue}
                </Typography>
              </Box>
              <Box sx={{ height: '1px', bgcolor: '#D9D9D9', mt: 2, opacity: 1 }} />
            </Grid>
          ))
        )}
      </Grid>
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <ButtonBase 
          sx={{ color: '#167D7F', textDecoration: 'underline' }}
          onClick={() => navigate('/add-complaint')}
        >
          File a new complaint
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default TenantDueList;