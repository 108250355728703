// src/middleware/websocketMiddleware.js (Homeowner App)

import WebSocketService from '../services/websocketService';
import { fetchDuesAndComplaints } from '../features/duesComplaintsSlice';
import { REHYDRATE } from 'redux-persist';

const websocketMiddleware = store => next => action => {
  if (action.type === 'auth/login/fulfilled') {
    WebSocketService.connect(action.payload);
    setupWebSocketListeners(store);
  }

  if (action.type === 'auth/logout') {
    WebSocketService.disconnect();
    console.log('WebSocket disconnected');
  }

  if (action.type === REHYDRATE) {
    console.log('Store rehydrated, checking WebSocket connection');
    const token = localStorage.getItem('token');
    if (token) {
      WebSocketService.reconnectIfNeeded();
      setupWebSocketListeners(store);
    }
  }

  if (action.type === 'APP_INITIALIZED') {
    console.log('App initialized, checking WebSocket connection');
    const token = localStorage.getItem('token');
    if (token) {
      WebSocketService.reconnectIfNeeded();
      setupWebSocketListeners(store);
    }
  }

  return next(action);
};

function setupWebSocketListeners(store) {
  
  WebSocketService.on('TENANT_UPDATE', () => {
    const state = store.getState();
    console.log('here in middleware TENANT UPDATE')
    const occupanyId = state.auth.user.occupanyId;
    if (occupanyId) {
      store.dispatch(fetchDuesAndComplaints('occupancy'));
    }
  });

  WebSocketService.on('DUE_UPDATE', () => {
    const state = store.getState();
    console.log('here in middleware DUE UPDATE')
    const tenantId = state.auth.user.tenantId;
    if (tenantId) {
      store.dispatch(fetchDuesAndComplaints('dues'));
    }
  });

  WebSocketService.on('COMPLAINT_UPDATE', () => {
    const state = store.getState();
    console.log('here in middleware COMPLAINT UPDATE')
    const tenantId = state.auth.user.tenantId;
    if (tenantId) {
      store.dispatch(fetchDuesAndComplaints('complaints'));
    }
  });
}

export default websocketMiddleware;