import React, { useState } from 'react';
import { Box, Typography, IconButton, TextField, Radio, RadioGroup, FormControlLabel, Button, ButtonBase, Dialog, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import FileUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDuesAndComplaints } from '../features/duesComplaintsSlice';
import Modal from '../item-components/messagebox';
import { BASE_URL } from '../utils/IP';

const ReportComplaint = () => {
  const [complaintType, setComplaintType] = useState('Electricity');
  const [remarks, setRemarks] = useState('');
  const [mediaFiles, setMediaFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const { user } = useSelector(state => state.auth);
  const tenantDetails = useSelector((state) => state.dueAndComplaint.currentOccupancy);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleComplaintTypeChange = (event) => {
    setComplaintType(event.target.value);
  };

  const handleRemarksChange = (event) => {
    setRemarks(event.target.value);
  };

  const handleMediaChange = (event) => {
    const files = Array.from(event.target.files);
    const newMediaFiles = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setMediaFiles(prevFiles => [...prevFiles, ...newMediaFiles]);
  };

  const handleRemoveMedia = (index) => {
    setMediaFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('category', complaintType);
      formData.append('description', remarks);
      formData.append('tenant', tenantDetails.tenant._id);
      formData.append('room', tenantDetails.room._id);
      formData.append('property', tenantDetails.property._id);
      formData.append('status', 'Pending');
      
      mediaFiles.forEach((mediaFile, index) => {
        formData.append('media', mediaFile.file);
      });
  
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
      };
  
      console.log('Sending request with data:', Object.fromEntries(formData));
  
      const response = await axios.post(`${BASE_URL}/complaints`, formData, config);
  
      console.log('Complaint Submitted:', response.data);
      setModalMessage('Complaint submitted successfully!');
      setIsModalOpen(true);
      dispatch(fetchDuesAndComplaints('complaints'));
      navigate('/tapp-home');
    } catch (error) {
      console.error('Error submitting complaint:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
        console.error('Error response status:', error.response.status);
        console.error('Error response headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setModalMessage('Error submitting complaint. Please try again.');
      setIsModalOpen(true);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', p: 0 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', p:2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)'}}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Report a complaint</Typography>
      </Box>

      {/* Complaint Type */}
      <Box sx={{ mb: 0, p:2 }}>
        <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
          Select the option to report
        </Typography>
        <RadioGroup
          row
          value={complaintType}
          onChange={handleComplaintTypeChange}
          color="secondary"
        >
          <FormControlLabel
            value="Electricity"
            control={<Radio sx={{ '&.Mui-checked': { color: '#03A0A2' }}} />}
            label="Electricity"
          />
          <FormControlLabel
            value="Water"
            control={<Radio sx={{ '&.Mui-checked': { color: '#03A0A2' }}} />}
            label="Water"
          />
          <FormControlLabel
            value="Hygiene"
            control={<Radio sx={{ '&.Mui-checked': { color: '#03A0A2' }}} />}
            label="Hygiene"
          />
          <FormControlLabel
            value="Staff"
            control={<Radio sx={{ '&.Mui-checked': { color: '#03A0A2' }}} />}
            label="Staff"
          />
          <FormControlLabel
            value="Security"
            control={<Radio sx={{ '&.Mui-checked': { color: '#03A0A2' }}} />}
            label="Security"
          />
        </RadioGroup>
      </Box>

      {/* Remarks */}
      <Box sx={{ mb: 2, p:2 }}>
        <Typography variant="subtitle2" sx={{ color: '#027071', mb: 0.5 }}>
          Complaint Details
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Enter detailed complaint here"
          value={remarks}
          onChange={handleRemarksChange}
        />
      </Box>

      {/* Upload Images */}
      <Box sx={{m:2}}>
        <Grid container spacing={2}>
          {mediaFiles.map((media, index) => (
            <Grid item xs={4} key={index}>
              <Box sx={{ position: 'relative', width: '100%', paddingTop: '100%' }}>
                <img 
                  src={media.preview} 
                  alt={`Preview ${index + 1}`}
                  style={{ 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    width: '100%', 
                    height: '100%', 
                    objectFit: 'cover', 
                    borderRadius: '8px', 
                    cursor: 'pointer' 
                  }}
                  onClick={() => {
                    setPreviewImage(media.preview);
                    setOpenImagePreview(true);
                  }}
                />
                <IconButton
                  sx={{ position: 'absolute', top: -8, right: -8, bgcolor: 'white' }}
                  onClick={() => handleRemoveMedia(index)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid>
          ))}
          <Grid item xs={4}>
            <ButtonBase 
              sx={{ 
                p:2, 
                display: 'flex', 
                flexDirection: 'column',
                width:'100%', 
                height: '100%',
                justifyContent:'center', 
                alignItems: 'center',
                bgcolor: '#E5F4F3', 
                borderRadius: '8px',
                minHeight: '100px'
              }}
              component="label"
            >
              <FileUploadIcon sx={{color: '#167D7F'}} />
              <Typography variant="body2" sx={{ color: '#167D7F', mt: 1 }}>
                Add Image
              </Typography>
              <input type="file" accept="image/*" multiple style={{ display: 'none' }} onChange={handleMediaChange} />
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>

      {/* Submit Button */}
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, p: 2, bgcolor: '#fff', boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)' }}>
        <Button
          variant="contained"
          fontSize={'h3'}
          fullWidth
          sx={{ color: '#fff', bgcolor: '#03A0A2', textTransform: 'none', borderRadius: 2, p: 1, fontSize: 16 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>

      {/* Modals */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <p>{modalMessage}</p>
      </Modal>

      <Dialog open={openImagePreview} onClose={() => setOpenImagePreview(false)} maxWidth="md">
        <img src={previewImage} alt="Full size preview" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </Box>
  );
};

export default ReportComplaint;