// TenantHome.js
import React, { useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  ButtonBase,
  Card,
  CardContent,
  Avatar, CircularProgress
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { dueAndComplaint } from '../features/duesComplaintsSlice'; // Redux actions
import { logout } from '../features/authSlice';



const TenantHome = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Safely access the state values
  //const dueAndComplaintState = useSelector((state) => state.dueandcomplaint || {});
  //const tenantdetails = dueAndComplaintState.tenantDetails; 
 // const user = useSelector((state) => state.auth?.user);
  //const loading = dueAndComplaintState.loading;
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { dues, loading,complaints } = useSelector((state) => state.dueAndComplaint);

  const tenantDetails = useSelector((state) => state.dueAndComplaint.currentOccupancy);

  console.log('Auth state:', { user, isAuthenticated });
  console.log('DueAndComplaint state:', { tenantDetails, dues, loading,complaints });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!tenantDetails) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography>No tenant details available. Please try logging in again.</Typography>
      </Box>
    );
  }


  const transactions = dues
    .filter((due) => due.status === 'Paid')
    .sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime));
  
  const handleProfileClick = () => {
    navigate('/tapp-profile'); // Go back to the profile
    };

    const handleReportComplaintClick = () => {
      navigate('/tapp-addcomplaint'); // Navigate to the report complaint page
    };

    const handleComplaintlistClick = () => {
      navigate('/tapp-complaints'); // Navigate to the report complaint page
    };

    const handleDuelistClick = () => {
      navigate('/tapp-dues'); // Navigate to the report complaint page
    };

    
    const handleAllTransactionsClick = () => {
      navigate('/tapp-transactions'); // Navigate to the report complaint page
    };

    const handleLogout = async () => {
      dispatch(logout());
      navigate('/tapp-login');
    };

  return (
    <Container sx={{ bgcolor: '#FCFCFC', minHeight: '100vh', py: 0, px:0 }}>
      {/* Header */}
      <Box sx={{ background: 'linear-gradient(-45deg, rgba(82,192,183,1) 0%, rgba(3,160,162,1) 50%, rgba(0,73,82,1) 100%)'}}>
      <Card sx={{ pt:3, bgcolor:'transparent', boxShadow:0}}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' , mr:2, ml:2}}>
        <Box
          component="img"
          sx={{ height: 25, width: 25, mt:-2 }}
          alt="greeting"
          src={`${process.env.PUBLIC_URL}/icons/VectorSun.png`}
        />          
        <Box sx={{paddingLeft:2,paddingRight:2, justifySelf:'left' }}>
          <Typography variant="body1" sx={{color:'#FFF', fontWeight:'600',textTransform:'capitalize',overflow:'hidden',textOverflow:'ellipsis'}}>
            Good morning, {tenantDetails?.tenant.name}
          </Typography>
          <Typography variant="body2" sx={{color:'#FFF', fontWeight:'300'}}>1 Jul 2024</Typography>
        </Box>
        <Box onClick={handleProfileClick} sx={{ cursor: 'pointer' }}>
          <Avatar
              src={`${process.env.PUBLIC_URL}/icons/DP.png`} // Replace with actual image URL
              alt="Profile"
              sx={{ width: 56, height: 56, mr: 2 }}
              
            />
        </Box>
      </CardContent>
      </Card>
      
      <Box sx={{ height: '1px', bgcolor: '#F6F6F6',mt:0 ,opacity:0.4}} />
      {/* Balance Due */}
      <Card sx={{ pb:4, bgcolor:'transparent', boxShadow:0}}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',mr:2, ml:2, mt:1,mb:1 }}>
          <Box>
            <Typography variant="body1" sx={{color:'#FFF', fontWeight:'500', pb:0.8}}>Aug'24 Balance Due</Typography>
            <Typography variant="h4" sx={{ color:'#FFF', fontWeight: 'bold' }}>₹20,000</Typography>
          </Box>
          <ButtonBase
            sx={{
              bgcolor: '#FFF',
              color: '#000',
              fontWeight:'500',
              borderRadius: '10px',
              px: 3,
              py: 1,
              textTransform: 'none',
              alignSelf: 'flex-end',
              mb:1
            }}
          >
            Analytics
          </ButtonBase>
        </CardContent>
      </Card>
      </Box>
      {/* Recent Transactions */}
      <Box sx={{ p: 2, mt: -4, bgcolor: '#FFF', borderRadius: '40px 40px 0 0' }}>
        <Box sx={{ mr: 18, ml:18, height: '4px',borderRadius:10, bgcolor: '#D9D9D9',mt:0 ,opacity:1}} />
        <Typography variant="body1" sx={{mt:3, ml:1, fontWeight:'bold'}}>Recent Transactions</Typography>
        <Grid container spacing={2} sx={{ mt: 1 ,paddingRight:1, paddingLeft:1 }}>
          {transactions.slice(0,3).map((transaction, index) => (
            <Grid item xs={12} key={transaction._id}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ mr: 2, color: '#000'}} />
                  <Box>
                    <Typography variant="body1">{transaction.dueType}</Typography>
                    <Typography variant="body2" color="textSecondary">{new Date(transaction.paymentDateTime).toLocaleDateString()}, {transaction.paymentMode}</Typography>
                    
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>₹{transaction.paymentAmount.toLocaleString()}</Typography>
              </Box>
              <Box sx={{ height: '1px', bgcolor: '#D9D9D9',mt:2 ,opacity:1}} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <ButtonBase sx={{ color: '#167D7F', textDecoration: 'underline' }} onClick={handleAllTransactionsClick}>See all transactions</ButtonBase>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <ButtonBase sx={{ color: '#167D7F', textDecoration: 'underline' }} onClick={handleComplaintlistClick}>See all Complaints</ButtonBase>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <ButtonBase sx={{ color: '#167D7F', textDecoration: 'underline' }} onClick={handleDuelistClick}>See all Dues</ButtonBase>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <ButtonBase sx={{ color: '#167D7F', textDecoration: 'underline' }} onClick={handleLogout}>LOGOUT</ButtonBase>
        </Box>
        
        <ButtonBase
          sx={{
            mt:2,
            display: 'flex',
            width: '100%',
            p: 2,
            bgcolor: '#E5F4F3',
            borderRadius: '10px',
            textAlign: 'left',
            mb: 2,
            textTransform: 'none',
          }}
          onClick={handleReportComplaintClick}
        >
          <AssignmentIcon sx={{mr:2, paddingTop:0 ,alignSelf: 'flex-start',fontSize: 30, color: '#167D7F'}} />
          <Box >
          <Typography variant="body1" sx={{ fontweight:'bold'}}>Report a complaint</Typography>
          <Typography variant="body2" color="textSecondary">
            If you have any issues or concerns, please submit your response here.
          </Typography>
          </Box>
          <ArrowForwardIosIcon sx={{ml: 2, mr:2, paddingTop:0.5 ,alignSelf: 'flex-start',fontSize: 15, color: '#167D7F'}}/>
        </ButtonBase>
        <ButtonBase
          sx={{
            type:'outlined',
            display: 'flex',
            justifyContent:'center',
            width: '100%',
            p: 2,
            bgcolor: '#FFF1D0',
            borderRadius: '10px',
            textAlign: 'left',
            textTransform: 'none',
          }}
        >
          <StarIcon sx={{mr:2,fontSize: 30, color: '#000'}} />
          <Typography variant="body1">Give your feedback for Rizzy App </Typography>
          
          <ChevronRightIcon sx={{ ml:2 ,mr:2, color: '#000'}}/>
        </ButtonBase>
      </Box>
    </Container>
  );
};

export default TenantHome;